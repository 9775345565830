<template>
  <div>
    <page-header :title="$t('paths.connections')" />
    <v-row class="mt-6 mr-6">
      <v-col
        v-if="$vuetify.breakpoint.name !== 'xs'"
        :cols="
          $vuetify.breakpoint.width < 600
            ? '0'
            : $vuetify.breakpoint.width < 680
            ? '2'
            : $vuetify.breakpoint.width < 800
            ? '4'
            : $vuetify.breakpoint.width < 960
            ? '6'
            : $vuetify.breakpoint.width < 1010
            ? '7'
            : $vuetify.breakpoint.width < 1264
            ? '8'
            : $vuetify.breakpoint.width < 1420
            ? '1'
            : '1'
        "
      ></v-col>
      <v-col
        :cols="
          $vuetify.breakpoint.width < 540
            ? '12'
            : $vuetify.breakpoint.width < 600
            ? '6'
            : $vuetify.breakpoint.width < 680
            ? '4'
            : $vuetify.breakpoint.width < 800
            ? '3'
            : $vuetify.breakpoint.width < 960
            ? '2'
            : $vuetify.breakpoint.width < 1264
            ? '1'
            : $vuetify.breakpoint.width < 1420
            ? '7'
            : $vuetify.breakpoint.width < 1745
            ? '8'
            : '9'
        "
      >
        <v-row
          :class="
            $vuetify.breakpoint.width < 540
              ? 'd-flex justify-center'
              : $vuetify.breakpoint.width < 600
              ? 'd-flex justify-start'
              : 'd-flex justify-end'
          "
        >
          <t2t-active-connections-overview
            v-if="isCommercialVersion"
          ></t2t-active-connections-overview>
        </v-row>
      </v-col>
      <v-col
        :cols="
          $vuetify.breakpoint.width < 540
            ? '12'
            : $vuetify.breakpoint.width < 600
            ? '6'
            : $vuetify.breakpoint.width < 680
            ? '6'
            : $vuetify.breakpoint.width < 800
            ? '5'
            : $vuetify.breakpoint.width < 960
            ? '4'
            : $vuetify.breakpoint.width < 1010
            ? '4'
            : $vuetify.breakpoint.width < 1264
            ? '3'
            : $vuetify.breakpoint.width < 1420
            ? '4'
            : $vuetify.breakpoint.width < 1745
            ? '3'
            : '2'
        "
      >
        <v-row
          :class="
            $vuetify.breakpoint.width < 540
              ? 'd-flex justify-center'
              : 'd-flex justify-end'
          "
        >
          <v-btn
            elevation="2"
            color="light blue"
            dark
            class="mx-2 text-no-wrap"
            @click="addConnectionBox"
          >
            {{ $t("buttons.addNew") }}
          </v-btn>
          <t2t-buy-more-button v-if="isCommercialVersion"></t2t-buy-more-button>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mb-3"></v-row>
    <div v-for="i in connectionBoxes" :key="i.connectionBoxId">
      <connection-box
        :connectionBoxId="i.connectionBoxId"
        :connectionState="i.isActive"
        @remove-this-box="removeThisBox"
      />
    </div>
    <v-row v-show="connectionBoxes.length === 0">
      <v-spacer></v-spacer>
      <span class="text-h5 mt-16"> {{ $t("noConnectionsYet") }} </span>
      <v-spacer></v-spacer>
    </v-row>
    <v-row v-show="connectionBoxes.length === 0">
      <v-spacer></v-spacer>
      <span class="text-h5"> {{ $t("addByClicking") }} </span>
      <v-spacer></v-spacer>
    </v-row>
    <v-snackbar right top app color="success" v-model="addedConnectionBox">
      {{ $t("notificationSnackbars.connectionBox") }}
      #{{ $t("connectionBoxIdNew") }}{{ this.used }}
      {{ $t("notificationSnackbars.wasSuccessfullyAdded") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="addedConnectionBox = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar right top app color="success" v-model="removedConnectionBox">
      {{ $t("notificationSnackbars.connectionBox") }}
      #{{ this.numberOfRemovedConnectionBox }}
      {{ $t("notificationSnackbars.wasSuccessfullyRemoved") }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          :loading="takingBack"
          @click="
            loader = takingBack;
            takeBackClickedWhenRemoving();
          "
        >
          {{ $t("buttons.takeBack") }}
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
        <v-btn text v-bind="attrs" @click="removedConnectionBox = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar right top app color="success" v-model="takingBackSuccess">
      {{ $t("notificationSnackbars.connectionRestored") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="takingBackSuccess = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar right top app color="error" v-model="takingBackError">
      {{ $t("notificationSnackbars.unableToRestoreConnection") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="takingBackError = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar right top app color="error" v-model="fetchConnectionsError">
      {{ $t("notificationSnackbars.errorWhileFetchingConnections") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="fetchConnectionsError = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import { Constants } from "../constants/Constants";
import {
  getConfObjects,
  getTimeEntries,
} from "@timer2ticket/timer2ticket-client-vue-library";
import {
  fetchUserConnections,
  restoreConnection,
} from "../services/backendService";
import {
  getConnectionBox,
  getSyncJobDefinition,
  mapConnectionToConnectionBox,
} from "../components/connections/shared";

export default {
  data() {
    return {
      isCommercialVersion: Constants.IS_COMMERCIAL_VERSION,

      connectionBoxes: [],
      used: 0,
      addedConnectionBox: false,
      removedConnectionBox: false,
      gainedMembership: false,
      fetchConnectionsError: false,
      numberOfRemovedConnectionBox: 0,

      takingBack: false,
      takingBackError: false,
      takingBackSuccess: false,

      pollingSetInterval: null,
    };
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  created() {
    this.connectionBoxes = this.$store.state.userConnectionBoxes;
    this.used = this.$store.state.usedUserConnectionBoxes;
    if (this.$route.params.gainedMembership === true) {
      setTimeout(() => {
        this.gainedMembership = true;
      }, 300);
    }

    this.pollConnections();
  },
  components: {
    "page-header": () => import("../components/PageHeader.vue"),
    "connection-box": () =>
      import("../components/connections/ConnectionBox.vue"),
  },
  methods: {
    async pollConnections() {
      await this.fetchConnections();
      this.pollingSetInterval = setInterval(async () => {
        await this.fetchConnections();
      }, Constants.POLLING_INTERVAL);
    },

    async fetchConnections() {
      const connections = await fetchUserConnections();
      if (connections === null) {
        this.fetchConnectionsError = true;
      } else {
        this.processConnections(connections);
      }
    },

    processConnections(connections) {
      const connectionBoxes = this.$store.state.userConnectionBoxes;
      for (let i = 0; i < connections.length; i++) {
        const connection = connections[i];
        const connectionBox = mapConnectionToConnectionBox(connection);
        let alreadyExists = false;
        for (let pos = 0; pos < connectionBoxes.length; pos++) {
          if (
            connectionBoxes[pos].connectionBoxId ===
            connectionBox.connectionBoxId
          ) {
            if (
              JSON.stringify(connectionBoxes[pos]) !==
              JSON.stringify(connectionBox)
            ) {
              // update connection box
              connectionBoxes[pos].isActive = connectionBox.isActive;
              connectionBoxes[pos].schedule = connectionBox.schedule;
              EventBus.$emit(
                "connection-box-saved",
                connectionBox.connectionBoxId,
                connectionBox.connectionBoxId
              );
            }
            alreadyExists = true;
            break;
          }
        }
        if (!alreadyExists) {
          connectionBoxes.push(connectionBox);
        }
      }
      this.$store.dispatch("changeUserConnectionBoxes", connectionBoxes);
    },

    addConnectionBox() {
      const defaultSelectionOfDays = [0, 1, 2, 3, 4, 5, 6];
      const defaultSyncTime = "02:30";
      const defaultEveryHour = true;

      let confObjects = getSyncJobDefinition(
        defaultSelectionOfDays,
        defaultSyncTime,
        defaultEveryHour
      );
      let timeEntries = getSyncJobDefinition(
        defaultSelectionOfDays,
        defaultSyncTime,
        defaultEveryHour
      );

      if (this.isCommercialVersion) {
        confObjects = getConfObjects(this.$store.state.membership.name);
        timeEntries = getTimeEntries(this.$store.state.membership.name);
      }
      this.used += 1;
      const boxId = this.$t("connectionBoxIdNew") + this.used;
      const isActive = false;

      this.connectionBoxes.push(
        getConnectionBox(null, boxId, isActive, confObjects, timeEntries)
      );
      this.$store.dispatch("changeUserConnectionBoxes", this.connectionBoxes);
      setTimeout(() => {
        EventBus.$emit("connection-box-open", boxId);
      }, 80);
      setTimeout(() => {
        this.addedConnectionBox = true;
      }, 300);
    },
    async takeBackClickedWhenRemoving() {
      this.takingBack = true;
      let removedConnectionBoxPosition = JSON.parse(
        sessionStorage.getItem("removedConnectionBoxPosition")
      );
      let removedConnectionBox = JSON.parse(
        sessionStorage.getItem(
          "removedConnectionBox-" + removedConnectionBoxPosition
        )
      );

      let response;
      if (
        removedConnectionBox.connectionBoxId
          .toString()
          .startsWith(this.$t("connectionBoxIdNew"))
      ) {
        response = true;
      } else {
        response = await restoreConnection(removedConnectionBox.databaseId);
      }

      if (!response) {
        this.takingBackError = true;
      } else {
        this.takingBackSuccess = true;
        this.connectionBoxes.push(removedConnectionBox);
        this.connectionBoxes.sort(function (a, b) {
          if (a.connectionBoxId < b.connectionBoxId) {
            return -1;
          }
          if (a.connectionBoxId > b.connectionBoxId) {
            return 1;
          }
          return 0;
        });
        this.removedConnectionBox = false;
        this.$store.dispatch("changeUserConnectionBoxes", this.connectionBoxes);
      }

      this.takingBack = false;
    },
    removeThisBox(i) {
      this.saveThisBoxToTheSessionStorage(i);
      this.connectionBoxes.splice(
        this.connectionBoxes.findIndex(
          (connectionBox) => connectionBox.connectionBoxId === i
        ),
        1
      );
      this.$store.dispatch("changeUserConnectionBoxes", this.connectionBoxes);
      this.numberOfRemovedConnectionBox = i;
      setTimeout(() => {
        this.removedConnectionBox = true;
      }, 300);
    },
    saveThisBoxToTheSessionStorage(pos) {
      for (let i = 0; i < this.connectionBoxes.length; ++i) {
        if (this.connectionBoxes[i].connectionBoxId === pos) {
          sessionStorage.setItem(
            "removedConnectionBox-" + this.connectionBoxes[i].connectionBoxId,
            JSON.stringify(this.connectionBoxes[i])
          );
          sessionStorage.setItem(
            "removedConnectionBoxPosition",
            JSON.stringify(this.connectionBoxes[i].connectionBoxId)
          );
          break;
        }
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.pollingSetInterval);
  },
};
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
